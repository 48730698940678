<template>
  <div :class="textClass" :v-html="text">{{ text }}
    <slot></slot>
  </div>

</template>

<script>
import {computed} from "vue";

export default {
  name: "ibiola-text",
  props: {
    text: {
      required: true,
      type: [String, Number]
    },
    type: {
      required: false,
      type: String,
    }
  },
  setup(props) {
    const allowed = [
      'welcome-headline',
      'heading',
      'section-headline',
      'card-title',
      'subheading',
      'subheading2',
      'car-title',
      'h1',
      'label',
      'label-small-gray',
      'text-small',
      'value',
      'button-label-small',
      'menu',
      'card-content',
      'heading-months',
      'help-headlines',
      'help-answer',
      'checkboxes-consent',
      'text-small-gray',
    ];


    const textClass = computed(() => {
      return {
        'text-3xl leading-8 font-bold tracking-tight': props.type === 'welcome-headline',
        'text-xl leading-7 font-semibold': props.type === 'heading',
        'text-2xl leading-7 font-bold': props.type === 'section-headline',
        'text-base leading-6 font-semibold': props.type === 'subheading' || props.type === 'card-title',
        'text-sm leading-5 font-normal text-gray-500': props.type === 'subheading2',
        'text-2xl leading-8 font-semibold': props.type === 'car-title',
        'text-sm leading-5 font-semibold': props.type === 'label',
        'text-sm leading-none font-normal': props.type === 'text-small',
        'text-sm font-semibold leading-5': props.type === 'text-small-bold',
        'text-xs leading-none font-normal text-gray-500': props.type === 'text-small-gray',
        'text-xs leading-5 font-semibold text-gray-500': props.type === 'text-small-gray-bold',
        'text-xs leading-none font-normal text-danger': props.type === 'text-small-warning',
        'text-base leading-6 font-normal': props.type === 'value',
        'text-xs leading-none font-normal': props.type === 'button-label-small',
        'text-xs leading-4 font-normal': props.type === 'menu',
        'text-xs leading-4 font-semibold uppercase text-gray-600': props.type === 'heading-months',
        'text-sm leading-5 font-medium text-gray-900 tracking-tight': props.type === 'help-headlines',
        'font-normal text-sm leading-6 tracking-tight text-gray-500': props.type === 'help-answer',
        'text-sm leading-5 font-normal': props.type === 'h1' || props.type === 'card-content' || props.type === 'checkboxes-consent' || !allowed.includes(props.type),
      };
    });
    return {
      textClass
    };
  }
}
</script>

<style scoped>

</style>
